import React, { useState } from 'react';

// Import your floor plan images
import TwoBHKImage from '../../img/PHOTO-2024-08-04-10-36-15.jpg';
import ThreeBHKImage from '../../img/PHOTO-2024-08-04-10-36-14.jpg';
import FirstToThirdFloorImage from '../../img/FIRST_THIRD FLOOR_page-0001.jpg';
import UpperGroundFloorImage from '../../img/UPPER_GROUND FLOOR _page-0001.jpg';
import EighthToTenthFloorImage from '../../img/TYPICAL_8,9,10_FLOOR_PLAN_page-0001.jpg';

function Floorplan() {
    // Set '2bhk' as the default selected floor plan
    const [selectedPlan, setSelectedPlan] = useState('2bhk');

    // Function to handle plan selection
    const handlePlanClick = (plan) => {
        setSelectedPlan(plan);
    };

    // Render the selected image based on the current plan
    const renderSelectedImage = () => {
        switch (selectedPlan) {
            case '2bhk':
                return <img src={TwoBHKImage} alt="2 BHK Floor Plan" className="w-full h-auto mt-4 shadow-lg" />;
            case '3bhk':
                return <img src={ThreeBHKImage} alt="3 BHK Floor Plan" className="w-full h-auto mt-4 shadow-lg" />;
            case '1st-to-3rd-floor':
                return <img src={FirstToThirdFloorImage} alt="1st to 3rd Floor Plan" className="w-full h-auto mt-4 shadow-lg" />;
            case 'upper-ground-floor':
                return <img src={UpperGroundFloorImage} alt="Upper Ground Floor Plan" className="w-full h-auto mt-4 shadow-lg" />;
            case '8th-to-10th-floor':
                return <img src={EighthToTenthFloorImage} alt="8th, 9th, 10th Floor Plan" className="w-full h-auto mt-4 shadow-lg" />;
            default:
                return <p className="text-gray-500 mt-4">Select a floor plan to view the image.</p>;
        }
    };

    return (
        <div className="container mx-auto p-4 mt-12">
         


            <h2 className="text-3xl font-bold text-center mb-8">Floor Plans</h2>
            <div className="text-center">
                <h1 className="uppercase text-4xl font-extrabold mb-6">
                    Irish Orison <span className="text-yellow-500">Property</span> Floor Plans
                </h1>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-center">
                <div 
                    onClick={() => handlePlanClick('2bhk')} 
                    className={`p-4 border rounded-lg cursor-pointer ${selectedPlan === '2bhk' ? 'bg-yellow-300' : 'hover:bg-gray-100'}`}>
                    <p className="text-xl font-medium">2BHK</p>
                </div>
                <div 
                    onClick={() => handlePlanClick('3bhk')} 
                    className={`p-4 border rounded-lg cursor-pointer ${selectedPlan === '3bhk' ? 'bg-yellow-300' : 'hover:bg-gray-100'}`}>
                    <p className="text-xl font-medium">3BHK</p>
                </div>
                <div 
                    onClick={() => handlePlanClick('1st-to-3rd-floor')} 
                    className={`p-4 border rounded-lg cursor-pointer ${selectedPlan === '1st-to-3rd-floor' ? 'bg-yellow-300' : 'hover:bg-gray-100'}`}>
                    <p className="text-xl font-medium">1st to 3rd Floor</p>
                </div>
                <div 
                    onClick={() => handlePlanClick('upper-ground-floor')} 
                    className={`p-4 border rounded-lg cursor-pointer ${selectedPlan === 'upper-ground-floor' ? 'bg-yellow-300' : 'hover:bg-gray-100'}`}>
                    <p className="text-xl font-medium">Upper Ground Floor</p>
                </div>
                <div 
                    onClick={() => handlePlanClick('8th-to-10th-floor')} 
                    className={`p-4 border rounded-lg cursor-pointer ${selectedPlan === '8th-to-10th-floor' ? 'bg-yellow-300' : 'hover:bg-gray-100'}`}>
                    <p className="text-xl font-medium">8th, 9th, 10th Floor Plan</p>
                </div>
            </div>
            <div className="mt-8">
                {renderSelectedImage()}
            </div>
        </div>
    );
}

export default Floorplan;
