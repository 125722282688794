import React, { useState } from 'react';
import { IoMdCall, IoLogoWhatsapp } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import { FaInstagramSquare, FaFacebook, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import contact from '../../img/yellow_dudes.jpg';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the WhatsApp message
    const whatsappMessage = `Name: ${name}\nEmail: ${email}\nSubject: ${subject}\nMessage: ${message}`;
    
    // Encode the message and create the WhatsApp link
    const whatsappLink = `https://api.whatsapp.com/send?phone=919881257256&text=${encodeURIComponent(whatsappMessage)}`;

    // Open the WhatsApp link
    window.open(whatsappLink, '_blank');

    // Optionally, clear the form fields
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
  };

  return (
    <div>
      <div className='relative'>
        <img src={contact} alt="Contact Us" className='w-full h-64 object-cover' />
        <h1 className='absolute inset-0 flex items-center justify-center text-4xl font-bold text-white bg-black bg-opacity-50'>Contact Us</h1>
      </div>

      <div className='max-w-7xl mx-auto p-8'>
        <div className='grid md:grid-cols-2 gap-8'>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <h1 className="text-yellow-600 font-bold text-3xl mb-6">Enquire Now</h1>
            <input 
              type="text" 
              placeholder="Name" 
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-yellow-600" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              required 
            />
            <input 
              type="email" 
              placeholder="Email" 
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-yellow-600" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
            <input 
              type="text" 
              placeholder="Subject" 
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-yellow-600" 
              value={subject} 
              onChange={(e) => setSubject(e.target.value)} 
              required 
            />
            <textarea 
              placeholder="Message" 
              rows="6" 
              className="w-full rounded-md px-4 py-3 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-yellow-600" 
              value={message} 
              onChange={(e) => setMessage(e.target.value)} 
              required 
            ></textarea>
            <button 
              type="submit" 
              className="text-white bg-yellow-600 hover:bg-yellow-800 font-semibold rounded-md text-sm px-6 py-3 w-full transition duration-200"
            >
              Send
            </button>
          </form>

          <div className='space-y-6'>
            <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
              <ContactCard icon={<IoMdCall />} label="Call" value="+91 9881257256" />
              <ContactCard icon={<IoLogoWhatsapp />} label="WhatsApp" value="+91 9881257256" />
            </div>
            <div className='space-y-6'>
              <ContactCard icon={<ImLocation />} label="Address" value="Flat No 103 1st Floor Shreeram Prestige, High School Road Wakharbhag Sangli, Sangli Ho, Sangli - 416416" />
              <ContactCard icon={<MdEmail />} label="Email" value="mhrishikesh10@gmail.com" />
            </div>
            <div className='grid grid-cols-2 gap-6 md:grid-cols-4'>
              <SocialCard icon={<FaFacebook />} label="Facebook" link="https://www.facebook.com/chaitanya.kulkarni.338" />
              <SocialCard icon={<FaInstagramSquare />} label="Instagram" link="#" />
              <SocialCard icon={<FaLinkedin />} label="LinkedIn" link="#" />
              <SocialCard icon={<IoLogoWhatsapp />} label="WhatsApp" link="https://api.whatsapp.com/send?phone=919881257256" />
            </div>
          </div>
        </div>
      </div>

      <div className='w-full h-64 md:h-96 mt-8'>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15274.402131784835!2d74.5705601002636!3d16.846162043223845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc11866b7f9e33d%3A0x6a8f5c126b7877d0!2sShiv%20Plaza!5e0!3m2!1sen!2sin!4v1723790461811!5m2!1sen!2sin"
          className="w-full h-full"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Location Map"
        ></iframe>
      </div>
    </div>
  );
}

function ContactCard({ icon, label, value }) {
  return (
    <div className='shadow-lg rounded-lg bg-white p-6 flex items-start space-x-4'>
      <div className='bg-yellow-200 p-4 rounded-full'>
        <p className='text-xl text-yellow-600'>{icon}</p>
      </div>
      <div>
        <p className='text-lg font-semibold text-gray-800'>{label}</p>
        <p className='text-sm text-gray-600'>{value}</p>
      </div>
    </div>
  );
}

function SocialCard({ icon, label, link }) {
  return (
    <a href={link} className='shadow-lg rounded-lg bg-white p-6 flex flex-col items-center space-y-4 hover:bg-yellow-100 transition duration-200'>
      <div className='bg-yellow-200 p-4 rounded-full'>
        <p className='text-xl text-yellow-600'>{icon}</p>
      </div>
      <p className='text-sm font-semibold text-gray-800'>{label}</p>
    </a>
  );
}

export default Contact;
