import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Balaji from './Component/Balaji/Balaji';
import NavMenu from './Component/NavMenu/NavMenu';
import Footer from './Component/Contact/Footer';
import Plotng from './Component/Ploting/Plotng';
import Insorgin from './Component/Insorjin/Insorgin';
function App() {
  return (
    <div>
    <Router>
      <NavMenu/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/balaji' element={<Balaji/>} />
        <Route path='/plotng' element={<Plotng/>} />
        <Route path='/orison' element={<Insorgin/>} />       
      </Routes>
      <Footer/>
    </Router>
  </div>
  );
}

export default App;
