import React from 'react';
import about from '../../img/slider2.jpg';
import img1 from '../../img/PHOTO-2024-07-15-14-20-43.jpg';
import img2 from '../../img/PHOTO-2024-07-15-14-20-44.jpg';
import img3 from '../../img/PHOTO-2024-07-15-14-20-45.jpg';
import img4 from '../../img/PHOTO-2024-08-04-10-36-11.jpg';
import owner from '../../img/owner.png';

import { FaHome, FaBuilding, FaIndustry } from 'react-icons/fa';
import { MdPeopleAlt, MdVerified, MdThumbUp, MdSecurity } from 'react-icons/md';

function About() {
  return (
    <div className='bg-gray-100'>
      <div className='relative'>
        <img src={about} alt="About Us" className='w-full h-64 object-cover' />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center pt-10">
          <h1 className='text-4xl font-bold text-white mb-2 '>About Us</h1>
          <h4 className='text-xl text-yellow-400 font-semibold text-center md:px-0 px-10'>
            Chaitanya Construction: Building Trust, Delivering Quality
          </h4>
        </div>
      </div>

      <div className='container mx-auto px-4 py-8'>
        <div className='flex flex-wrap justify-center gap-8'>
          <img src={img1} alt="Construction Work" className='w-full md:w-1/3 rounded-lg shadow-lg' />
          <img src={img2} alt="Construction Site" className='w-full md:w-1/3 rounded-lg shadow-lg' />
          <img src={img3} alt="Construction Work" className='w-full md:w-1/3 rounded-lg shadow-lg' />
          <img src={img4} alt="Construction Site" className='w-full md:w-1/3 rounded-lg shadow-lg' />
        </div>

        <div className='mt-8'>
          <div className="flex flex-col md:flex-row items-center ">
            {/* Text Section */}
            <div className="mb-6 md:mb-0 md:w-1/2">
              <h2 className="text-3xl font-extrabold text-gray-800 mb-4">About Us</h2>
              <p className="text-lg text-gray-700 leading-relaxed">
                Welcome to Chaitanya Constructions, your trusted construction partner. With 10 years of experience, we have been at the forefront of the construction industry, specializing in residential, commercial, and industrial projects. Our commitment to delivering quality and innovation has earned us a reputation for excellence.
              </p>
            </div>

            {/* Image Section */}
            <div className="md:w-1/2 flex flex-col items-center justify-center">
              <img
                src={owner}
                alt="Owner of Chaitanya Constructions"
                className="w-64 h-64 object-cover rounded-full shadow-lg border-4 border-yellow-500 mb-4"
              />
              <h1 className="text-xl font-semibold text-gray-800 mt-2">Chaitanya Kulkarni</h1>
            </div>

          </div>


          <div className='mb-6'>
            <h2 className='text-2xl font-bold text-gray-800 mb-4'>Our Mission</h2>
            <p className='text-lg text-gray-700'>
              At Chaitanya Constructions, our mission is to build sustainable, reliable structures that not only meet but exceed our clients' expectations, enhancing the communities we serve.
            </p>
          </div>

          <div className='mb-6'>
            <h2 className='text-2xl font-bold text-gray-800 mb-4'>Services</h2>
            <div className='space-y-4'>
              <div className='flex items-start space-x-4'>
                <FaHome className='text-2xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Residential</h3>
                  <p className='text-lg text-gray-700'>
                    We create custom homes tailored to your needs and handle renovations with precision and care.
                  </p>
                </div>
              </div>
              <div className='flex items-start space-x-4'>
                <FaBuilding className='text-2xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Commercial</h3>
                  <p className='text-lg text-gray-700'>
                    From modern offices to dynamic retail spaces, we build commercial environments that foster success.
                  </p>
                </div>
              </div>
              <div className='flex items-start space-x-4'>
                <FaIndustry className='text-2xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Industrial</h3>
                  <p className='text-lg text-gray-700'>
                    We construct robust warehouses and infrastructure that support industrial growth and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='mb-6'>
            <h2 className='text-2xl  font-bold text-gray-800 mb-4'>Why Choose Us?</h2>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
              <div className='flex shadow-lg rounded-lg items-start space-x-4 p-4'>
                <MdPeopleAlt className='text-3xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Expert Team</h3>
                  <p className='text-lg text-gray-700'>
                    Our team consists of skilled professionals dedicated to delivering excellence in every project.
                  </p>
                </div>
              </div>
              <div className='flex shadow-lg rounded-lg items-start space-x-4 p-4'>
                <MdVerified className='text-3xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Quality Assurance</h3>
                  <p className='text-lg text-gray-700'>
                    We use top-quality materials and the latest techniques to ensure the durability and aesthetic appeal of our constructions.
                  </p>
                </div>
              </div>
              <div className='flex shadow-lg rounded-lg items-start space-x-4 p-4'>
                <MdThumbUp className='text-3xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Customer Focused</h3>
                  <p className='text-lg text-gray-700'>
                    We offer tailored solutions that align with our clients' visions and needs, ensuring satisfaction at every stage.
                  </p>
                </div>
              </div>
              <div className='flex shadow-lg rounded-lg items-start space-x-4 p-4'>
                <MdSecurity className='text-3xl text-yellow-500' />
                <div>
                  <h3 className='text-xl font-semibold text-gray-800'>Safety First</h3>
                  <p className='text-lg text-gray-700'>
                    We prioritize safety with strict protocols on all job sites, safeguarding our workers and the community.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default About;
