import React from 'react';
import floarplan from '../../img/balajiflor.jpg';
import img1 from '../../img/balajiflat1.jpg';
import img2 from '../../img/balajiflat2.jpg';
import img3 from '../../img/balajiflat3.jpg';
import img4 from '../../img/balajiflat5.jpg';

function Balajifloar() {
  return (
    <div className="container mx-auto p-6 mt-12">
      {/* Floor Plan Section */}
      <h2 className="text-3xl font-bold text-center mb-8">Floor Plans</h2>
      <div className="text-center mb-10">
        <h1 className="uppercase text-4xl font-extrabold mb-6">
          Balaji Orison <span className="text-yellow-500">Property</span> Floor Plans
        </h1>
        <img
          src={floarplan}
          alt="Balaji Orison Floor Plan"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      {/* Interior Image Section */}
      <div className="text-center">
        <h1 className="text-3xl font-semibold mb-6">
          Explore the Interiors of Balaji Orison <span className="text-yellow-500">Flats</span>
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <img
            src={img1}
            alt="Balaji Orison Flat Interior 1"
            className="w-full h-64 rounded-lg shadow-md"
          />
          <img
            src={img2}
            alt="Balaji Orison Flat Interior 2"
            className="w-full h-64 rounded-lg shadow-md"
          />
          <img
            src={img3}
            alt="Balaji Orison Flat Interior 3"
            className="w-full h-64 rounded-lg shadow-md"
          />
          <img
            src={img4}
            alt="Balaji Orison Flat Interior 4"
            className="w-full h-64 rounded-lg shadow-md"
          />
        </div>
      </div>
    </div>
  );
}

export default Balajifloar;
