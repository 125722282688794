import React from 'react';
import { FaMapMarkerAlt, FaRegMoneyBillAlt, FaHandshake, FaHome, FaPhoneAlt, FaLandmark } from 'react-icons/fa';
import plotImage1 from '../../img/karnalimage.jpeg'; // Example plot images
import plotImage2 from '../../img/karnalplot.jpeg';
import plotImage3 from '../../img/kupwad.jpeg';
import plotImage4 from '../../img/g3.png';
import heroBg from '../../img/contact.jpg'; // Background image for the hero section
import { Link } from 'react-router-dom';

const plotsData = [
  {
    id: 1,
    title: 'Inam Dhamni - 484/B',
    address: '1 acre of scenic land, perfect for residential or commercial use.',
    description: 'This 1-acre plot offers ample space for development in a peaceful, well-connected area.',
    image: plotImage1,
  },
  {
    id: 2,
    title: 'Karnal Village - Chaitanya Strusti',
    address: 'Survey No. 546, Karnal Village',
    description: 'An exclusive plot that blends rural tranquility with urban conveniences.',
    image: plotImage2,
  },
  {
    id: 3,
    title: 'Kupwad - Ratna Dream Land',
    address: '7 acres in front of EAL International School, Bamnoli',
    description: 'Spacious land, ideal for residential colonies or large institutional projects.',
    image: plotImage3,
  },
  {
    id: 4,
    title: 'Kanadwadi, Miraj - Gut No. 26',
    address: 'Survey No. Gut 26, Kanadwadi Village',
    description: 'A serene plot, perfect for agricultural or residential use in a peaceful location.',
    image: plotImage4,
  },
];

const reasons = [
  {
    icon: <FaMapMarkerAlt className="text-yellow-600 text-3xl" />,
    title: 'Prime Locations',
    description: 'Our plots are strategically located, ensuring easy access to essential services.',
  },
  {
    icon: <FaHome className="text-yellow-600 text-3xl" />,
    title: 'Quality Assurance',
    description: 'Chaitanya Construction ensures that all our plots meet the highest quality standards.',
  },
  {
    icon: <FaHandshake className="text-yellow-600 text-3xl" />,
    title: 'Transparency and Trust',
    description: 'We prioritize transparency in all dealings, ensuring peace of mind for our buyers.',
  },
  {
    icon: <FaRegMoneyBillAlt className="text-yellow-600 text-3xl" />,
    title: 'Affordable Pricing',
    description: 'Competitive pricing with no compromise on quality, giving excellent value.',
  },
  {
    icon: <FaPhoneAlt className="text-yellow-600 text-3xl" />,
    title: 'Flexible Payment Plans',
    description: 'Flexible payment options to suit your budget and help you secure your investment.',
  },
  {
    icon: <FaLandmark className="text-yellow-600 text-3xl" />,
    title: 'Infrastructure Development',
    description: 'We contribute to the overall development of the area, enhancing property values.',
  },
];

function Plotng() {
  return (
    <div>
      {/* Hero Section */}
      <div className="relative bg-cover bg-center h-80" style={{ backgroundImage: `url(${heroBg})` }}>
        <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
          <h1 className="text-4xl pt-10 font-bold text-white text-center">Invest in Your Future with Chaitanya Construction</h1>
        </div>
      </div>

      {/* Reasons to Buy a Plot Section */}
      <div className="py-12 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-10">Why Buy a Plot from Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {reasons.map((reason, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300">
                <div className="flex items-center mb-4">
                  {reason.icon}
                  <h3 className="text-lg font-semibold text-gray-800 ml-2">{reason.title}</h3>
                </div>
                <p className="text-gray-600">{reason.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Available Plots Section */}
      <div className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-center text-4xl font-bold text-yellow-600 mb-8">Available Plots</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
            {plotsData.map((plot) => (
              <div key={plot.id} className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition duration-300">
                <img src={plot.image} alt={plot.title} className="h-48 w-full object-cover" />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-gray-800">{plot.title}</h2>
                  <p className="text-gray-600 mt-2">{plot.address}</p>
                  <p className="text-gray-500 mt-4">{plot.description}</p>
                </div>
                <div className="bg-yellow-500 text-white p-4 text-center">
                  <Link to='/contact'><button className="rounded-md px-4 py-2 font-semibold hover:bg-yellow-600 transition duration-200">
                    Book Now
                  </button></Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plotng;
