import React from 'react';
import img7 from '../../../img/7.jpg';
import img1 from '../../../img/1.png';
import img2 from '../../../img/2.jpg';
import img3 from '../../../img/3.jpg';
import img4 from '../../../img/4.jpg';
import img5 from '../../../img/5.png';
import img6 from '../../../img/6.png';

function Complelteproject() {
    return (
        <div className="py-10 px-6 bg-gray-100">
              <h1 className="text-4xl font-bold text-center mb-12">
              Our <span className="text-yellow-600 text-6xl">Completed Projects</span>
      </h1>
            <div className='grid grid-cols-1 md-grid-cols-2 lg:grid-cols-2'>
            <div className="mb-8">
                <img src={img7} alt="Main Project" className="w-full h-auto shadow-lg" />
                <p className="text-center text-lg font-semibold mt-2 text-gray-600">Balaji Orison, Government Colony</p>
            </div>
            
            {/* Thumbnail Images */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 ml-4">
                {[
                    { img: img1, caption: "Sukhkarta Residency, Dattanagar" },
                    { img: img2, caption: "Sai Suman, Vijaynagar" },
                    { img: img3, caption: "Sai Vijay, Pragati Colony" },
                    { img: img4, caption: "Anuradha Nakshatra" },
                    { img: img5, caption: "Anuradha Pooram" },
                    { img: img6, caption: "Anuradha Annex, Warnali" },
                ].map((project, index) => (
                    <div key={index}>
                        <img src={project.img} alt={`Project Image ${index + 1}`} className="w-full h-48  shadow-md" />
                        <p className="text-center text-sm mt-1 uppercase text-gray-600 font-semibold">{project.caption}</p>
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
}

export default Complelteproject;
