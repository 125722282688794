import React from 'react';
import { IoMdCall, IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook, FaPhoneAlt } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

function OurTeam() {
    return (
        <div className="mb-16">
            <div className="container flex justify-center mx-auto pt-16">
                <div>
                    <h1 className="xl:text-6xl text-6xl text-center text-yellow-600  font-extrabold pb-6 sm:w-4/6 w-5/6 mx-auto">
                    FOUNDER MEMBERS</h1>
                    <p className="text-gray-500 text-lg text-center font-normal pb-12">The Talented People Behind the Scenes of the Organization </p>

                </div>
            </div>
            <div className="w-full bg-gray-100 px-10 pt-10">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-8">
                        {[
                            {
                                name: 'CHAITANYA KULKARNI',
                                title: 'Chief Executive Officer',
                                imgSrc: 'https://cdn.tuk.dev/assets/photo-1564061170517-d3907caa96ea.jfif',
                            },
                            {
                                name: 'TANA KULKARNI',
                                title: 'Product Design Head',
                                imgSrc: 'https://cdn.tuk.dev/assets/photo-1530577197743-7adf14294584.jfif',
                            },
                            {
                                name: 'ANIL ALDAR',
                                title: 'Manager Development',
                                imgSrc: 'https://cdn.tuk.dev/assets/photo-1566753323558-f4e0952af115.jfif',
                            },
                            {
                                name: 'SAGAR MANE',
                                title: 'Principal Software Engineer',
                                imgSrc: 'https://cdn.tuk.dev/assets/boy-smiling_23-2148155640.jpg',
                            },
                            {
                                name: 'BHUPAL SARGAR',
                                title: 'Product Design Head',
                                imgSrc: 'https://cdn.tuk.dev/assets/photo-1570211776045-af3a51026f4a.jfif',
                            },
                            {
                                name: 'MAHESH SHINDE',
                                title: 'Manager Development',
                                imgSrc: 'https://cdn.tuk.dev/assets/photo-1570211776045-af3a51026f4a.jfif',
                            },
                            {
                                name: 'KEDAR KHAPARE',
                                title: 'Manager Development',
                                imgSrc: 'https://cdn.tuk.dev/assets/photo-1570211776045-af3a51026f4a.jfif',
                            },
                        ].map((member, index) => (
                            <div
                                key={index}
                                className={`relative mb-16 ${index < 3 ? 'lg:col-span-1' : index < 7 ? 'lg:col-span-1 sm:col-span-1' : 'lg:col-span-3'}`}
                            >
                                <div className="rounded overflow-hidden shadow-md bg-white">
                                    <div className="absolute -mt-20 w-full flex justify-center">
                                        <div className="h-32 w-32">
                                            <img
                                                src={member.imgSrc}
                                                alt={`Display Picture of ${member.name}`}
                                                role="img"
                                                className="rounded-full object-cover h-full w-full shadow-md"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-6 mt-16">
                                        <h1 className="font-bold text-3xl text-center mb-1">{member.name}</h1>
                                        <p className="text-gray-800 text-sm text-center">{member.title}</p>
                                        <div className="w-full flex justify-center pt-5 pb-5 space-x-4">
                                            <a href="#"><FaPhoneAlt /></a>
                                            <a href="#"><FaFacebook /></a>
                                            <a href="#"><IoLogoWhatsapp /></a>
                                            <a href="#"><AiFillInstagram /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurTeam;
