import React, { useState, useEffect } from 'react';
import img1 from '../.././../img/innovative-architecture-civil-engineering-plan-innovative-architecture-civil-engineering-building-construction-project-170431310.jpg';
import img2 from '../.././../img/innovative-architecture-civil-engineering-plan-building-construction-project-creative-graphic-design-showing-concept-174256642.jpg';
import img3 from '../.././../img/innovative-architecture-civil-engineering-plan-innovative-architecture-civil-engineering-building-construction-project-172973391.jpg';

const slides = [
    {
        img: img1,
        title: 'CIVIL ENGINEERING',
        text: 'Our team of expert architects, structural and civil engineers are well-versed with the operators’ needs, aspirations, and dynamics of the market. We offer effective cooperation and service flexibility; due to the highly qualified team of professionals, our company can develop a solution even for the most challenging projects.',
        caption: 'Professional Engineering',
    },
    {
        img: img2,
        title: 'CONSTRUCTION WORK',
        text: 'With a team of experienced builders and energetic designers, we are committed to providing the finest civil construction services. We are engaged in offering an excellent quality service of civil engineering. We undertake several kinds of commercial and non-commercial projects that include Civil Construction works as well.',
        caption: 'Excellent Quality Service',
    },
    {
        img: img3,
        title: 'COMMERCIAL PROJECTS',
        text: 'Built on a deep understanding of life in India, we proudly build sustainable infrastructure for people and cities to connect. Project management from Ashoka means comprehensive supervision, inspection, and commissioning services for every phase of your construction project.',
        caption: 'Efficiency: We are not new to this business.',
    },
];

function Services() {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 3000); 

        return () => clearInterval(slideInterval);
    }, []);

    return (
        <div className="bg-gray-100 py-16">
            <div className="container mx-auto px-6">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex transition-all duration-700 ease-in-out">
                    <div className="md:w-1/2 relative">
                        <img
                            src={slides[currentSlide].img}
                            alt={slides[currentSlide].caption}
                            className="w-full h-full object-cover"
                        />
                        <p className="absolute bottom-0 left-0 right-0 bg-yellow-500 text-white text-center py-3 font-semibold text-lg opacity-90">
                            {slides[currentSlide].caption}
                        </p>
                    </div>

                    <div className="md:w-1/2 p-8 flex flex-col justify-center">
                        <h1 className="text-3xl font-bold text-gray-800 mb-6">
                            {slides[currentSlide].title}
                        </h1>
                        <p className="text-gray-600 text-lg leading-relaxed">
                            {slides[currentSlide].text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
