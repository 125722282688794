import React from 'react';
import img1 from '../../../img/PHOTO-2024-08-04-10-36-14-removebg-preview.png';
import img2 from '../../../img/PHOTO-2024-08-04-10-36-15-removebg-preview.png';
import bgcolor from '../../../img/homeflatbg.jpg';

function Diagram() {
    return (
        <div
            className="py-16 bg-cover bg-center"
            style={{ backgroundImage: `url(${bgcolor})` }}
        >
            
            <h1 className="text-4xl font-bold text-center mb-12">
            Discover Your Dream Home at<span className="text-yellow-600 text-6xl"> Irish Orison</span>
      </h1>
            <div className="container mx-auto px-6">
                {/* 3 BHK Flats Section */}
                <div className="flex flex-col-reverse md:flex-row items-center overflow-hidden mb-12">
                    <div className="md:w-1/2">
                        <img
                            src={img1}
                            alt="3 BHK Flats"
                            className="w-full h-auto object-cover drop-shadow-2xl"
                        />
                    </div>
                    <div className="md:w-1/2 p-8">
                        <h2 className="text-5xl md:text-6xl font-bold text-yellow-600 mb-4">3 BHK Flats</h2>
                        <p className="text-gray-600 text-lg mb-2">A Spacious Haven for Your Family</p>
                        <p className="text-gray-600 text-lg">Elegance and Sophistication</p>
                    </div>
                </div>

                {/* 2 BHK Flats Section */}
                <div className="flex flex-col md:flex-row items-center overflow-hidden">
                    <div className="md:w-1/2 p-8">
                        <h2 className="text-5xl md:text-6xl font-bold text-yellow-600 mb-4">2 BHK Flats</h2>
                        <p className="text-gray-600 text-lg mb-2">Perfect Space for Modern Living</p>
                        <p className="text-gray-600 text-lg">Affordable Luxury</p>
                    </div>
                    <div className="md:w-1/2">
                        <img
                            src={img2}
                            alt="2 BHK Flats"
                            className="w-full h-auto object-cover drop-shadow-2xl"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Diagram;
