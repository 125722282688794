import React from 'react';
import videoGif from '../../../img/videogif.gif';
import { Link } from 'react-router-dom';

function Getintouch() {
    return (
        <div className="relative flex flex-col md:flex-row justify-center items-center px-6 py-10 bg-gradient-to-r from-yellow-50 to-yellow-200">
            {/* Background Animation */}
            <div className="absolute inset-0 z-0 opacity-40">
                <img
                    src={videoGif}
                    alt="Background Animation"
                    className="w-full h-full object-cover"
                />
            </div>

            {/* Content Area */}
            <div className="relative z-10 bg-yellow-300 bg-opacity-90 backdrop-blur-lg py-16 px-8 rounded-xl shadow-lg text-center md:max-w-2xl border border-yellow-400">
                <h4 className="text-yellow-800 text-3xl font-bold mb-4">
                    GET IN TOUCH
                </h4>
                <h1 className="text-4xl font-extrabold mb-6 text-gray-800">
                    We’re Here For You
                </h1>
                <p className="text-lg text-gray-700 mb-8 max-w-md mx-auto">
                    Do you have a query about one of our properties? We are happy to speak with you! Leave us a message, and we will get back to you as soon as possible.
                </p>
                <Link
              to="/contact">     
                <button className="bg-yellow-600 hover:bg-yellow-700 text-white font-medium py-3 px-8 rounded-full shadow-lg transition-transform transform hover:scale-105">
                    Contact Us
                </button></Link>
            </div>
        </div>
    );
}

export default Getintouch;
