import React from 'react';
import bgcolor from '../../img/northeast-remsco-footer-bg.png';
import { IoMdCall } from "react-icons/io";
import { ImLocation2 } from "react-icons/im";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import logo from '../../img/chaitanyac.png';

function Footer() {
  return (
    <footer className="relative text-white py-8 bg-black">
      <div
        className="absolute inset-0 bg-gradient-to-t from-black via-black to-transparent opacity-50"
        style={{ backgroundImage: `url(${bgcolor})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      ></div>
      <div className="relative z-10 container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-6">
        <aside className="flex flex-col items-center">
          <img src={logo} alt="Chaitanya Construction Logo" className="w-32 h-auto" />
          <div className="flex space-x-4 mt-4">
            <div className="bg-white hover:bg-yellow-400 rounded-full p-2">
              <FaFacebook className="text-black" />
            </div>
            <div className="bg-white hover:bg-yellow-400 rounded-full p-2">
              <IoLogoWhatsapp className="text-black" />
            </div>
            <div className="bg-white hover:bg-yellow-400 rounded-full p-2">
              <AiFillInstagram className="text-black" />
            </div>
            <div className="bg-white hover:bg-yellow-400 rounded-full p-2">
              <FaLinkedin className="text-black" />
            </div>
          </div>
        </aside>
        <nav>
          <h6 className="text-lg font-bold mb-4">Our Projects</h6>
          <ul className="space-y-2">
            <li>
              <Link to="/orison" className="hover:text-yellow-400 transition-colors">Irish Orison</Link>
            </li>
            <li>
              <Link to="/balaji" className="hover:text-yellow-400 transition-colors">Balaji Orison</Link>
            </li>
            <li>
              <Link to="/plotng" className="hover:text-yellow-400 transition-colors">Plotting</Link>
            </li>
          </ul>
        </nav>
        <nav>
          <h6 className="text-lg font-bold mb-4">Company</h6>
          <ul className="space-y-2">
            <li>
              <Link to="/about" className="hover:text-yellow-400 transition-colors">About Us</Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-yellow-400 transition-colors">Contact</Link>
            </li>
          </ul>
        </nav>
        <nav>
          <h6 className="text-lg font-bold mb-4">Contact Us</h6>
          <ul className="space-y-2">
            <li className="flex items-center">
              <IoMdCall className="mr-2" />
              <span className="hover:text-yellow-400 transition-colors">9876543210</span>
            </li>
            <li className="flex items-center">
              <ImLocation2 className="mr-2" />
              <span className="hover:text-yellow-400 transition-colors">Flat No 103 1st Floor Shreeram Prestige, High School Road Wakharbhag Sangli, Sangli Ho, Sangli - 416416</span>
            </li>
          </ul>
        </nav>
      </div>
      <div className="text-center py-4 border-t border-white text-white mt-8">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Chaitanya Construction. All rights reserved. Design by{' '}
          <a href="https://www.businessmantra.info/" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-400 transition-colors">
            Business Mantra
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
