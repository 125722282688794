import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaMapLocationDot } from 'react-icons/fa6';
import { RiBuilding2Fill } from 'react-icons/ri';
import { BsBuildingsFill } from 'react-icons/bs';
import { FiMenu, FiX } from 'react-icons/fi';
import logo from '../../img/chaitanyac.png';

function NavMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [navbarBg, setNavbarBg] = useState('bg-transparent');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg('bg-white shadow-lg');
      } else {
        setNavbarBg('bg-transparent');
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`fixed top-0 left-0 right-0 ${navbarBg} transition-all duration-300 z-50 px-4 md:px-8`}>
      <div className="container mx-auto flex justify-between items-center ">
        {/* Logo */}
        <Link to="/">
          <img src={logo} alt="Chaitanya Construction Logo" className="w-28 h-auto z-10" />
        </Link>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden z-20">
          <button onClick={toggleMenu} className="text-3xl focus:outline-none">
            {isOpen ? <FiX className="text-yellow-600 " /> : <FiMenu className="text-yellow-600" />}
          </button>
        </div>

        {/* Nav Links */}
        <ul
          className={`flex-col md:flex-row md:flex space-y-6 md:space-y-0 space-x-0 md:space-x-6 gap-8 
          fixed md:static bg-white md:bg-transparent top-0 left-0 w-full md:w-auto 
          transform ${isOpen ? 'translate-y-0 md:mt-0 md:p-0 mt-24 p-8' : '-translate-y-full'} md:translate-y-0 transition-transform duration-500 ease-in-out md:flex md:items-center z-10`}
        >
          <li className="group">
            <Link
              to="/"
              onClick={toggleMenu}
              className="transition-transform duration-300 hover:scale-105 text-orange-500 hover:text-yellow-500 font-serif font-semibold text-lg tracking-wide"
            >
              Home
            </Link>
          </li>
          <li className="group">
            <Link
              to="/about"
              onClick={toggleMenu}
              className="transition-transform duration-300 hover:scale-105 text-orange-500 hover:text-yellow-500 font-serif font-semibold text-lg tracking-wide"
            >
              About Us
            </Link>
          </li>
          {/* Dropdown Menu for Our Projects */}
          <li className="group relative">
            <span className="cursor-pointer transition-transform duration-300 hover:scale-105 text-orange-500 hover:text-yellow-500 font-serif font-semibold text-lg tracking-wide">
              Our Projects
            </span>
            <ul className="absolute left-0 mt-2 w-48 bg-white text-blue-900 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20">
              <li className="px-4 py-2 transition-transform duration-300 hover:scale-105 hover:text-blue-600">
                <Link
                  to="/orison"
                  className="flex items-center space-x-2 font-serif font-semibold text-lg tracking-wide"
                  onClick={toggleMenu}
                >
                  <BsBuildingsFill className="text-lg" />
                  <span>Irish Orison</span>
                </Link>
              </li>
              <li className="px-4 py-2 transition-transform duration-300 hover:scale-105 hover:text-blue-600">
                <Link
                  to="/plotng"
                  className="flex items-center space-x-2 font-serif font-semibold text-lg tracking-wide"
                  onClick={toggleMenu}
                >
                  <FaMapLocationDot className="text-lg" />
                  <span>Ploting</span>
                </Link>
              </li>
              <li className="px-4 py-2 transition-transform duration-300 hover:scale-105 hover:text-blue-600">
                <Link
                  to="/balaji"
                  className="flex items-center space-x-2 font-serif font-semibold text-lg tracking-wide"
                  onClick={toggleMenu}
                >
                  <RiBuilding2Fill className="text-lg" />
                  <span>Balaji Orison</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="group">
            <Link
              to="/contact"
              onClick={toggleMenu}
              className="transition-transform duration-300 hover:scale-105 text-orange-500 hover:text-yellow-500 font-serif font-semibold text-lg tracking-wide"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavMenu;
