import React from 'react';
import img1 from '../../../img/Commercial.jpg';
import img2 from '../../../img/Residential.jpg';
import img3 from '../../../img/Industrial.jpg';
import img4 from '../../../img/plotting.jpeg';

const Sectors = () => {
  const sectors = [
    {
      title: 'Residential Construction',
      description: 'Custom Homes, Renovations, Apartment Complexes, Housing Developments',
      image: img2,
    },
    {
      title: 'Commercial Construction',
      description: 'Office Buildings, Retail Spaces, Shopping Centers, Restaurants',
      image: img1,
    },
    {
      title: 'Industrial Construction',
      description: 'Warehouses, Manufacturing Plants, Infrastructure Projects, Distribution Centers',
      image: img3,
    },
    {
      title: 'Plotting',
      description: 'Land Plotting for Residential, Commercial, and Industrial Purposes',
      image: img4,
    },
  ];

  return (
    <div className="w-full mx-auto p-6">
      <h1 className="text-4xl font-bold text-center mb-12">
        Sectors<span className="text-yellow-600 text-6xl"> We Work In</span>
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {sectors.map((sector, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
          >
            <img
              src={sector.image}
              alt={sector.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-2">{sector.title}</h3>
              <p className="text-gray-600">{sector.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sectors;
