import React from 'react'
import Navbar from '../NavMenu/NavMenu'
import Homepage from './Homepage'
import Services from './Homepage/Services'
import Diagram from './Homepage/Diagram'
import Sectors from './Homepage/Sectors'
import OurTeam from '../About/OurTeam'
import Getintouch from './Homepage/Getintouch'
import Complelteproject from './Homepage/Complelteproject'

function Home() {
  return (
    <div>
      <Homepage/>
      <Diagram/>
      <Sectors/>
      <Complelteproject/>
      {/* <Services/>
      <OurTeam/> */}
      <Getintouch/>
    </div>
  )
}

export default Home