import React from 'react';
import logo from '../../img/BALAJI_copy1-removebg-preview.png';
import Hotel from '../../img/balaji orison copy.png';
import Balajifloar from './balajifloar';

function Balaji() {
  return (
    <div>
    <div className="bg-gradient-to-r from-indigo-200 to-green-100 min-h-screen flex justify-center items-center">
      <div className="flex flex-col md:flex-row w-full max-w-7xl mx-auto p-6">
        {/* Left Section with Text and Logo */}
        <div className="relative w-full md:w-1/2 flex flex-col items-center justify-center mb-10 md:mb-0 md:mr-10 p-10">
          {/* Yellow background shape */}
          <div
            className="absolute inset-0 bg-lime-200 glass-effect rounded-lg"
          ></div>

          {/* Logo */}
          <img
            src={logo}
            alt="Logo"
            className="relative z-10 w-64 h-64 drop-shadow-lg mb-6"
          />

          {/* Title and Description */}
          <h1 className="relative z-10 text-4xl text-center font-extrabold text-gray-800 mb-4 uppercase">
            Premier Residential Apartments
          </h1>
          <h2 className="relative z-10 text-2xl md:text-3xl text-gray-600 mb-6">
            2/3 BHK
          </h2>
          <p className="relative z-10 text-lg md:text-xl text-gray-500 mb-8 text-center">
            Balaji Orison is the perfect blend of modern architecture and thoughtful design.
          </p>

          {/* Book Now Button */}
          <button className="relative z-10 bg-red-500 text-white py-3 px-12 rounded-full font-serif font-bold text-lg hover:bg-red-700 hover:shadow-lg transition duration-300 ease-in-out">
            Book Now
          </button>
        </div>

        {/* Right Section with Image */}
        <div className="relative w-full md:w-1/2 flex justify-center items-center">
          <img
            src={Hotel}
            alt="Luxurious Apartments"
            className="relative max-w-full h-auto md:h-full object-cover rounded-lg drop-shadow-lg"
          />
        </div>
      </div>
    </div>
    <Balajifloar/>
    </div>
  );
}

export default Balaji;
