import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import bgcolor from '../../img/backgroundhome.jpg';
import irsonlogo from '../../img/weblogocopy copy orison.png';
import balajilogo from '../../img/BALAJI_copy1-removebg-preview.png';
import irson from '../../img/weblogocopy1.png';
import balaji from '../../img/balajifake.png';
import irsonresponsive from '../../img/Irish Building copy.png';
import balajiresponsive from '../../img/balaji orison copy.png';

function Homepage() {
  const data = [
    {
      homename: irsonlogo,
      h1: 'Luxurious Apartments',
      h3: '2/3 BHK and Shops',
      p: 'Elevate your lifestyle with unmatched luxury.',
      buildingimgDesktop: irson,
      buildingimgMobile: irsonresponsive,
    },
    {
      homename: balajilogo,
      h1: 'Premier Residential Apartments',
      h3: '2/3 BHK Homes',
      p: 'Balaji Orison is the perfect blend of modern architecture and thoughtful design.',
      buildingimgDesktop: balaji,
      buildingimgMobile: balajiresponsive,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Auto-slide every 5 seconds
    return () => clearInterval(interval);
  }, [data.length]);

  const { h1, h3, p, buildingimgDesktop, buildingimgMobile, homename } =
    data[currentIndex];

  return (
    <div
      className="relative min-h-screen bg-cover bg-fixed bg-center"
      style={{ backgroundImage: `url(${bgcolor})` }}
    >
      <div className="absolute inset-0 bg-yellow-100 bg-opacity-20"></div>
      <div className="relative z-10 flex flex-col items-center justify-center text-center min-h-screen px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center justify-between w-full max-w-7xl mx-auto pt-20 md:pt-0">
          
          {/* Text Section */}
          <div className="text-white flex flex-col items-center space-y-4 animate-slideInLeft transition-all duration-500 md:mr-32">
            <img
              src={homename}
              alt="Home Name"
              className="w-4/5 md:w-3/4 lg:w-1/2 object-contain z-20 transition-transform transform hover:scale-110 duration-500 animate-scaleUp"
            />
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold uppercase text-yellow-500 drop-shadow-lg animate-fadeIn">
              {h1}
            </h1>
            <h3 className="text-lg md:text-2xl text-gray-800 animate-fadeIn delay-200">
              {h3}
            </h3>
            <p className="text-sm md:text-lg text-gray-800 max-w-md mx-auto animate-fadeIn delay-300">
              {p}
            </p>
             <Link
              to="/contact">
            <button className="bg-yellow-500 text-white py-2 px-10 rounded-lg font-bold text-lg hover:bg-yellow-600 transition duration-300 shadow-xl hover:shadow-2xl transform hover:scale-105">
              Book Now
            </button>
            </Link>
          </div>

          {/* Building Image Section */}
          <div className="flex justify-center">
            {/* Desktop Image */}
            <img
              src={buildingimgDesktop}
              alt="Building Desktop"
              className="hidden md:block w-auto max-w-5xl h-full mt-10 md:mt-0 object-contain animate-fadeIn"
            />
            {/* Mobile Image */}
            <img
              src={buildingimgMobile}
              alt="Building Mobile"
              className="block md:hidden w-full max-w-sm h-auto object-contain transition-transform transform hover:scale-105 duration-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
