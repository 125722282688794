import React from 'react';
import building from '../../img/Irish Building copy.png';
import orisonlogo from '../../img/weblogocopy copy orison.png';
import Floorplan from './Floorplan';

const Insorgin = () => {
  return (
    <div>
      {/* First Section with Gradient Background */}
      <div className="bg-gradient-to-r from-yellow-200 to-pink-100 min-h-screen flex justify-center items-center">
        <div className="flex flex-col lg:flex-row w-full max-w-6xl mx-auto p-6">
          {/* Left Section with Text and Logo */}
          <div className="relative w-full lg:w-1/2 flex flex-col items-center justify-center mb-10 lg:mb-0 lg:mr-10">
            {/* Yellow background shape */}
            <div
              className="absolute inset-0 bg-lime-200 glass-effect rounded-lg"
              style={{ zIndex: '-1' }} // Keeps the shape behind the content
            ></div>

            <div className="relative z-10 p-10 bg-yellow-100 text-center lg:text-left rounded-lg">
              <img src={orisonlogo} alt="Orison Logo" className="mb-4 mx-auto lg:mx-0" />
              <p className="text-gray-700 font-semibold text-lg mb-2">Premium Homes</p>
              <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">Luxurious Apartments</h2>
              <p className="text-xl font-semibold mb-6">2/3 BHK and Shops</p>
              <p className="text-gray-600 mb-8">Elevate your lifestyle with unmatched luxury.</p>
              <button className="bg-red-600 text-white px-6 py-2 w-64 md:w-96 rounded-full hover:bg-red-700 transition duration-300">
                Book Now
              </button>
            </div>
          </div>

          {/* Right Section with Image */}
          <div className="relative w-full lg:w-1/2 flex justify-center items-center">
            <img src={building} alt="Irish Orison building" className="w-full h-auto object-cover rounded-lg" />
          </div>
        </div>
      </div>

      {/* Floor Plan Section */}
      <div className="max-w-6xl mx-auto p-6">
        <Floorplan />
      </div>
    </div>
  );
};

export default Insorgin;
